//

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, alpha, Button } from '@mui/material';
// components
import Page from '../../components/Page';
import { ArrowBackIcon } from '../../assets';
// sections
import { LoginForm } from '../../sections/auth/login';
// asset

// ----------------------------------------------------------------------

const RootParentStyle = styled('div')(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  minHeight: '100vh',
  background: 'linear-gradient(360deg, rgba(36,154,25,1) 0%, rgba(75,171,86,1) 0%, rgba(39,255,0,1) 100%)',
}));

const RootStyle = styled('div')(({ theme }) => ({
  borderBottomRightRadius: '40px',
  borderBottomLeftRadius: '40px',
  position: 'relative',
  // height: '46vh',
  // backgroundColor: theme.palette.background.default,
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}));

const ContentStyle = styled('div')(() => ({
  maxWidth: 356,
  margin: 'auto',
  position: 'relative',
  top: '4vh',
}));

const LogInScreenStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '8vh',
  paddingInline: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  backgroundColor: alpha(theme.palette.grey[200], 0.94),
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  borderRadius: theme.spacing(2),
  boxShadow: theme.customShadows.card,
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <RootParentStyle>
        <RootStyle>
          <Container sx={{ px: 3 }}>
            <ContentStyle>
              <Box sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={'/'}
                  sx={{ p: 2, minWidth: 4, mb: 2 }}
                >
                  <ArrowBackIcon sx={{ display: 'flex' }} />
                </Button>

                <Typography noWrap variant="h4" sx={{ mt: 2 }}>
                  Sign in
                </Typography>
                <Typography variant="body2" sx={{ maxWidth: '250px', mt: 1 }}>
                  Sign in to discover amazing things near around you.
                </Typography>
              </Box>

              <LogInScreenStyle>
                <LoginForm />
              </LogInScreenStyle>
            </ContentStyle>
          </Container>
        </RootStyle>
      </RootParentStyle>
    </Page>
  );
}
